<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="$v.value.$model"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      ref="fieldWrapper"
      :field="field"
      :input-group="true"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <!------------ START: Prepend date icon ------------>
      <div v-if="!isVariablesField" class="input-group-prepend">
        <span
          class="input-group-text"
          :class="{ 'cursor-pointer': !isDisabled }"
        >
          <i class="fal fa-calendar" />
        </span>
      </div>
      <!------------ END: Prepend date icon ------------>
      <input
        v-if="!isVariablesField"
        :value="displayValue"
        class="form-control"
        :class="{ disabled: isDisabled }"
        type="text"
        readonly
        :placeholder="$t('formHelper.selectDate')"
      />
      <template v-if="!isVariablesField" #hints>
        <!------------ START: Date picker ------------>
        <v-date-picker
          v-if="showPicker"
          ref="picker"
          v-model="$v.value.$model"
          class="position-absolute border zindex-100 shadow"
          v-bind="pickerOptions"
        >
          <div class="w-100 text-right">
            <button class="btn btn-clean" @click="togglePicker">
              {{ $t("formHelper.ok") }}
            </button>
          </div>
        </v-date-picker>
        <!------------ END: Date picker ------------>
      </template>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import { formatDateTime } from "@/components/Tools/FormHelper/Helper/functions";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";

export default {
  components: { FieldWrapper, VariablesField },
  mixins: [base, variablesField, eagerValidation],
  expose: ["showPicker"],
  props: {},
  data() {
    return {
      date: [],
      showPicker: false,
      pickerOptions: {
        scrollable: true,
        min: this.field.min,
        max: this.field.max,
        range: this.field.range ?? false,
        locale: this.$i18n.locale,
        color: "primary",
        "selected-items-text": this.$t("formHelper.datePicker.itemsSelected")
      }
    };
  },
  computed: {
    displayValue: function () {
      let format = "DD.MM.YYYY";

      if (!this?.field?.range ?? true) {
        return this.value ? formatDateTime(this.value, format) : "";
      }

      return this.value.map(v => formatDateTime(v, format)).join(" - ");
    }
  },
  created() {
    window.addEventListener("click", this.onClick);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("click", this.onClick);
  },
  methods: {
    togglePicker() {
      this.showPicker = !this.showPicker;
    },
    onClick(e) {
      if (this.isDisabled) {
        return;
      }
      // Check if input was clicked
      if (this.$refs.fieldWrapper.$refs.inputGroup.contains(e.target)) {
        this.togglePicker();
        return;
      }
      // Check if picker is already hidden
      if (!this.$refs.picker) {
        return;
      }
      // Check if click was on picker
      this.showPicker = this.$refs.picker.$el.contains(e.target);
    },
    onInput(value) {
      if (Array.isArray(value) && value.length === 2) {
        value.sort((a, b) => new Date(a) - new Date(b));

        this.togglePicker();
      }

      const formatDate = val =>
        formatDateTime(val, this.field.format ?? "YYYY-MM-DD") || "";
      let payload = formatDate(value);

      if (this.isVariablesField) {
        payload = value;
      } else if (Array.isArray(value) && value.length > 0) {
        payload = value.map(formatDate);
      }

      this.$emit("input", payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.zindex-100 {
  z-index: 100;
}

:deep(.v-date-picker-table) {
  min-height: 260px;
}

:deep(.v-date-picker-table--date) {
  td {
    padding: 3px;
  }
}
</style>
